require("core-js/modules/es.array.index-of");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.login = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  var _default = {
    name: "FactoryLogin",
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        name: "",
        errText: ""
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var yType = vm.$route.query.yType;
      var uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (name && fType) {
        if (["lexing", "ruiyin", "yingwo", "lansong", "wangbo", "haopai"].indexOf(fType) > -1) {
          vm.$router.replace({
            path: "/factory/index?yType=".concat(yType || '')
          });
          vm.$localStorage.setItem('uuid', uuid);
          return;
        }

        vm.$router.replace({
          path: "404"
        });
      } else {
        if (!uuid) {
          vm.$localStorage.setItem(USER_NAME, "");
          vm.$localStorage.setItem("uuid", "");
          vm.$router.replace({
            path: "404"
          });
        }
      }
    },
    methods: {
      change: function change() {
        var vm = this;
        var han = /^[\u2E80-\u9FFF]+$/;
        var err = "";

        if (!vm.name) {
          err = "请输入您的名字~";
        }

        if (!han.test(vm.name)) {
          err = "请输入中文~";
        }

        vm.errText = err;
      },
      submitClick: function submitClick() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;

        if (vm.errText || !vm.name) {
          return;
        }

        var uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
        var fType = vm.$strTool.filterFactoryType(uuid);
        var yType = vm.$route.query.yType;
        vm.$localStorage.setItem(USER_NAME, vm.name);
        vm.$route.query.uuid && vm.$localStorage.setItem('uuid', uuid);

        if (["lexing", "ruiyin", "yingwo", "lansong", "wangbo", "haopai"].indexOf(fType) > -1) {
          vm.$router.replace({
            path: "/factory/index?yType=".concat(yType || '')
          });
          return;
        }

        vm.$router.replace({
          path: "404"
        });
      }
    }
  };
  _exports.default = _default;
});
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-factory-index" },
    [
      _c(
        "div",
        { staticClass: "user-header", on: { click: _vm.switchAccount } },
        [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: { src: _vm.ossHost + "img/logo_min.png", alt: "" }
            })
          ]),
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _c("span", { staticClass: "switch-account" }, [_vm._v("切换账号")])
        ]
      ),
      _c("div", { staticClass: "scan-body" }, [
        _vm.type == 3 || _vm.type == 5
          ? _c("div", { staticClass: "btn", on: { click: _vm.scan } }, [
              _c("img", {
                attrs: { src: _vm.ossHost + "img/icon_zhijian.png", alt: "" }
              }),
              _vm._v("扫码质检")
            ])
          : _vm._e(),
        _vm.type == 2
          ? _c("div", { staticClass: "btn", on: { click: _vm.vieProbeList } }, [
              _c("img", {
                attrs: { src: _vm.ossHost + "img/icon_zhijian.png", alt: "" }
              }),
              _vm._v("探头出厂质检")
            ])
          : _vm._e(),
        _vm.type == 5
          ? _c(
              "div",
              { staticClass: "btn", on: { click: _vm.viewQualityList } },
              [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/out_stock.png", alt: "" }
                }),
                _vm._v("质检记录")
              ]
            )
          : _vm._e(),
        _vm.type == 3 || _vm.type == 1 || _vm.type == 5 || _vm.type == 6
          ? _c(
              "div",
              {
                staticClass: "btn dingsun",
                on: {
                  click: function($event) {
                    return _vm.viewQualityList(1)
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/icon_dingsun.png", alt: "" }
                }),
                _vm._v(" "),
                _vm.count ? _c("span", [_vm._v(_vm._s(_vm.count))]) : _vm._e(),
                _vm._v(" 返厂定损")
              ]
            )
          : _vm._e(),
        _vm.type == 2
          ? _c(
              "div",
              {
                staticClass: "btn dingsun",
                on: { click: _vm.viewQualityList }
              },
              [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/icon_dingsun.png", alt: "" }
                }),
                _vm._v("登记返厂探头")
              ]
            )
          : _vm._e(),
        _vm.type == 3 || _vm.type == 5 || _vm.type == 6
          ? _c(
              "div",
              { staticClass: "btn", on: { click: _vm.viewDescription } },
              [
                _c("img", {
                  attrs: {
                    src: _vm.ossHost + "img/icon_description.png",
                    alt: ""
                  }
                }),
                _vm._v("使用说明")
              ]
            )
          : _vm._e(),
        _vm.type == 4 && !_vm.yType
          ? _c(
              "div",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.showModal = true
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/icon_zhijian.png", alt: "" }
                }),
                _vm._v("复核维护订单")
              ]
            )
          : _vm._e(),
        _vm.type == 4 && !_vm.yType
          ? _c(
              "div",
              {
                staticClass: "btn dingsun",
                on: { click: _vm.viewWarningList }
              },
              [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/icon_dingsun.png", alt: "" }
                }),
                _vm._v("核对异常探头")
              ]
            )
          : _vm._e(),
        _vm.type == 4 && _vm.yType == 1
          ? _c(
              "div",
              {
                staticClass: "btn dingsun",
                on: { click: _vm.onclickOutStock }
              },
              [
                _c("img", {
                  attrs: { src: _vm.ossHost + "img/out_stock.png", alt: "" }
                }),
                _vm._v("出库设备校验")
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "logo-box" }, [
          _c("img", { attrs: { src: _vm.ossHost + "img/logo.png", alt: "" } })
        ])
      ]),
      _c(
        "Popup",
        {
          on: {
            close: function($event) {
              _vm.probeId = ""
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "modal-box" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._v("请输入探头ID")
            ]),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c("Field", {
                  attrs: {
                    type: "number",
                    "input-align": "center",
                    placeholder: "请输入探头ID"
                  },
                  model: {
                    value: _vm.probeId,
                    callback: function($$v) {
                      _vm.probeId = $$v
                    },
                    expression: "probeId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "div",
                {
                  class: { btn: true, pass: _vm.isPass },
                  on: { click: _vm.loadProbeInfo }
                },
                [_vm._v("确认")]
              )
            ])
          ])
        ]
      ),
      _c(
        "Popup",
        {
          staticClass: "tl-setup-modal",
          attrs: { position: "center" },
          model: {
            value: _vm.showSetupModal,
            callback: function($$v) {
              _vm.showSetupModal = $$v
            },
            expression: "showSetupModal"
          }
        },
        [
          _c("div", { staticClass: "modal-setup-body" }, [
            _c("div", { staticClass: "model-header" }, [
              _c("img", {
                attrs: { src: _vm.ossHost + "img/icon-tl-box.jpg", alt: "" }
              })
            ]),
            _c("div", { staticClass: "model-header" }, [
              _vm._v(_vm._s(_vm.qaInfo.uniqueId))
            ]),
            _c(
              "div",
              {
                staticClass: "kzh-qa-item",
                on: {
                  click: function($event) {
                    return _vm.onclickResetQABtn(1, _vm.qaInfo.hasKzhQc)
                  }
                }
              },
              [
                _vm.qaInfo.hasKzhQc
                  ? _c("Icon", { attrs: { name: "passed" } })
                  : _c("Icon", { attrs: { name: "info" } }),
                _c("div", { staticClass: "item-info" }, [_vm._v("功能质检")]),
                _vm.qaInfo.hasKzhQc
                  ? _c("div", { staticClass: "item-btn" }, [_vm._v("重检")])
                  : _c("div", { staticClass: "item-btn" }, [_vm._v("开始")])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "kzh-qa-item",
                on: {
                  click: function($event) {
                    return _vm.onclickResetQABtn(2, _vm.qaInfo.hasKzhWeighQc)
                  }
                }
              },
              [
                _vm.qaInfo.hasKzhWeighQc
                  ? _c("Icon", { attrs: { name: "passed" } })
                  : _c("Icon", { attrs: { name: "info" } }),
                _c("div", { staticClass: "item-info" }, [
                  _c("div", { staticClass: "item-info-title" }, [
                    _vm._v("称重质检")
                  ]),
                  _c("div", { staticClass: "item-info-text" }, [
                    _vm._v("仅称重套装需做此步骤")
                  ])
                ]),
                _vm.qaInfo.hasKzhWeighQc
                  ? _c("div", { staticClass: "item-btn" }, [_vm._v("重检")])
                  : _c("div", { staticClass: "item-btn" }, [_vm._v("开始")])
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "Popup",
        {
          model: {
            value: _vm.showReQuality,
            callback: function($$v) {
              _vm.showReQuality = $$v
            },
            expression: "showReQuality"
          }
        },
        [
          _c("div", { staticClass: "tip-modal-box" }, [
            _c("div", { staticClass: "modal-title" }, [
              _vm._v("该控制盒已进行过质检")
            ]),
            _c("div", { staticClass: "modal-content" }, [
              _vm._v(
                "编号" +
                  _vm._s(_vm.qaInfo.uniqueId) +
                  "的控制盒已进行过出厂质检"
              )
            ]),
            _c("div", { staticClass: "modal-btn" }, [
              _c(
                "div",
                {
                  staticClass: "btn-item",
                  on: {
                    click: function($event) {
                      _vm.showReQuality = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "div",
                {
                  staticClass: "btn-item confirm",
                  on: { click: _vm.onclickResetFsBtn }
                },
                [_vm._v("再次质检")]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
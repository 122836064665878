require("core-js/modules/es.object.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.pondManageRouter = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pondManage = {
    path: '/pondManage',
    redirect: '/pondManage/index',
    name: 'PondManage',
    component: function component() {
      return import('@/Layout');
    },
    meta: {
      title: '塘管家风控管理'
    },
    children: [{
      path: 'index',
      component: function component() {
        return import('@/pages/pondManage/index');
      },
      name: 'PondManageIndex',
      meta: {
        title: '塘管家风控管理'
      }
    }, {
      path: 'clientList',
      component: function component() {
        return import('@/pages/pondManage/clientList');
      },
      name: 'ClientList',
      meta: {
        title: '我的客户'
      }
    }, {
      path: 'pondMonitoring',
      component: function component() {
        return import('@/pages/pondManage/pondMonitoring');
      },
      name: 'PondMonitoring',
      meta: {
        title: '塘管家风控管理'
      }
    }, {
      path: 'mapPage',
      component: function component() {
        return import('@/pages/pondManage/mapPage');
      },
      name: 'MapPage',
      meta: {
        title: '鱼塘定位'
      }
    }]
  };
  var _default = pondManage;
  _exports.default = _default;
});
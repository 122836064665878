(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.ipcImgList = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pond02ImgList = _exports.pond01ImgList = void 0;
  // 第一个塘 5864709874
  var pond01ImgList = [{
    date: "24/10/21",
    type: "使用投入品",
    images: ['https://img.yudada.com/juanPic/20241021/5864709874/5864709874_hd_1729500264_77633.png', 'https://img.yudada.com/juanPic/20241021/5864709874/5864709874_hd_1729500381_90599.png', 'https://img.yudada.com/juanPic/20241021/5864709874/5864709874_hd_1729500506_21039.png', 'https://img.yudada.com/juanPic/20241021/5864709874/5864709874_hd_1729500629_75572.png']
  }, {
    date: "24/05/16",
    type: "使用投入品",
    images: ['https://img.yudada.com/juanPic/20240516/5864709874/5864709874_hd_1715822325_28626.png', 'https://img.yudada.com/juanPic/20240516/5864709874/5864709874_hd_1715822199_91902.png', 'https://img.yudada.com/juanPic/20240516/5864709874/5864709874_hd_1715817731_75279.png']
  }]; // 第二个塘 664448072

  _exports.pond01ImgList = pond01ImgList;
  var pond02ImgList = [{
    date: "24/11/21",
    type: "使用投入品",
    images: ['https://img.yudada.com/juanPic/20241021/6664448072/6664448072_hd_1729469881_89370.png', 'https://img.yudada.com/juanPic/20241021/6664448072/6664448072_hd_1729498848_32685.png', 'https://img.yudada.com/juanPic/20241021/6664448072/6664448072_hd_1729499419_88205.png']
  }, {
    date: "24/10/24",
    type: "下苗",
    images: ['https://img.yudada.com/juanPic/20241024/6664448072/6664448072_hd_1729723653_22837.png', 'https://img.yudada.com/juanPic/20241024/6664448072/6664448072_hd_1729727647_55025.png', 'https://img.yudada.com/juanPic/20241024/6664448072/6664448072_hd_1729726470_76779.png']
  }];
  _exports.pond02ImgList = pond02ImgList;
});
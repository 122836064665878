(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports);
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports);
    global.clientList = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  var _default = {
    name: "ClientList",
    data: function data() {
      return {
        token: "974CD7950C9A12E52D2905E6855F74D3E28FF3B1AF5310671CDE71343A615607",
        clientList: []
      };
    },
    created: function created() {
      var vm = this;
      vm.getUserList();
    },
    methods: {
      // 获取客户列表
      getUserList: function getUserList() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.DEALER_WECHAT_BASE_URL, "riskAssessment/userList"),
          headers: {
            "x-access-token": vm.token
          }
        }).then(function (response) {
          vm.clientList = response.data;
        }).catch(function (error) {
          console.error(error);
        });
      },
      // 跳转到客户详情
      onclickClientDetail: function onclickClientDetail(index) {
        var selectItem = this.clientList[index];
        this.$router.push({
          path: "/pondManage/pondMonitoring",
          query: {
            selectItem: JSON.stringify(selectItem)
          }
        });
      }
    }
  };
  _exports.default = _default;
});
require("core-js/modules/es.object.to-string");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "./factory.router", "./ryProbe.router", "./pondManage.router"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("./factory.router"), require("./ryProbe.router"), require("./pondManage.router"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.factory, global.ryProbe, global.pondManage);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _factory, _ryProbe, _pondManage) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _factory = _interopRequireDefault(_factory);
  _ryProbe = _interopRequireDefault(_ryProbe);
  _pondManage = _interopRequireDefault(_pondManage);
  var routes = [{
    path: '/',
    name: 'Index',
    component: function component() {
      return import('@/pages/index');
    }
  }, _factory.default, _ryProbe.default, _pondManage.default, {
    path: '/wechatWebView',
    name: 'WechatWebView',
    component: function component() {
      return import('@/pages/wechatWebView');
    },
    meta: {
      title: '公告详情'
    }
  }, {
    path: '*',
    name: 'NotFount',
    component: function component() {
      return import('@/pages/notFount');
    },
    meta: {
      title: '404'
    }
  }, {
    path: '/videoCourse',
    name: 'VideoCourse',
    component: function component() {
      return import('@/pages/videoCourse');
    },
    meta: {
      title: '安装教程'
    }
  }];
  var _default = routes;
  _exports.default = _default;
});
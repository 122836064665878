require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.join");

require("core-js/modules/es.array.map");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.number.to-fixed");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/image-preview/style", "vant/es/image-preview", "vant/es/toast/style", "vant/es/toast", "vant/es/popup/style", "vant/es/popup", "../pondManage/ipcImgList"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/image-preview/style"), require("vant/es/image-preview"), require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/popup/style"), require("vant/es/popup"), require("../pondManage/ipcImgList"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.imagePreview, global.style, global.toast, global.style, global.popup, global.ipcImgList);
    global.pondMonitoring = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _imagePreview, _style2, _toast, _style3, _popup, _ipcImgList) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _imagePreview = _interopRequireDefault(_imagePreview);
  _toast = _interopRequireDefault(_toast);
  _popup = _interopRequireDefault(_popup);
  var _default = {
    name: "PondMonitoring",
    components: {
      Popup: _popup.default
    },
    data: function data() {
      return {
        ossHost: "https://static.yudada.com/wechat/pond/",
        token: "974CD7950C9A12E52D2905E6855F74D3E28FF3B1AF5310671CDE71343A615607",
        // 鱼塘数据顶导航 0: 鱼塘数据 1: 鱼塘监控
        pondDataLabel: 0,
        // 是否显示产量提示
        isShowYieldTip: false,
        // 是否显示所有数据
        isShowAllData: false,
        pondDataInfo: {},
        showChooseDaysRecordPopUp: false,
        //选择日期时右上角年月日
        year: "",
        month: "",
        day: "",
        //一个月份的多少天，
        days: [],
        //一个月份的第一天是周几
        daysInFirst: "",
        //选中的日期
        chooseDay: "",
        chooseYear: "",
        chooseMonth: "",
        url: "",
        showVideoPopUp: false,
        videoLoading: 0,
        picList: [],
        nowDate: "",
        pond01ImgList: _ipcImgList.pond01ImgList,
        pond02ImgList: _ipcImgList.pond02ImgList,
        // 投料分数
        userScoreOption: {
          animation: false,
          series: [{
            type: "gauge",
            progress: {
              show: true,
              width: 16
            },
            axisLine: {
              show: true,
              lineStyle: {
                width: 16,
                color: [[1, "#D9D9D9"]]
              }
            },
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            },
            data: [{
              value: 0
            }],
            itemStyle: {
              normal: {
                //渐变色
                color: {
                  type: "radial",
                  x: 0.2,
                  y: 1,
                  r: 1,
                  colorStops: [{
                    offset: 0,
                    color: "#48BB7B"
                  }, {
                    offset: 1,
                    color: "#187F46"
                  }]
                }
              }
            }
          }]
        },
        // 溶氧曲线
        oxygenChartOption: {
          animation: false,
          grid: {
            containLabel: true,
            top: "20px",
            left: "5px",
            right: "5px",
            bottom: "5px"
          },
          tooltip: {
            show: true,
            trigger: "axis",
            // tooltip位置,限制在手指位置上方的80px
            position: function position(point) {
              return [point[0], point[1] - 80];
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [10, 20, 40, 60, 80, 100, 120, 140, 160, 140, 120, 100, 80, 60, 40, 20, 10]
          },
          yAxis: {
            x: "center",
            type: "value",
            interval: 6,
            splitLine: {
              show: false
            }
          },
          series: []
        },
        // 投料分柱状图
        feedChartOption: {
          grid: {
            containLabel: true,
            top: "10px",
            left: "0",
            right: "6px",
            bottom: "8px"
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            },
            // tooltip位置,限制在手指位置上方的80px
            position: function position(point) {
              return [point[0], point[1] - 80];
            }
          },
          xAxis: {
            data: []
          },
          yAxis: {
            type: "value",
            interval: 800,
            splitLine: {
              lineStyle: {
                type: "dashed"
              }
            }
          },
          series: []
        },
        selectInfoItem: {}
      };
    },
    created: function created() {
      var vm = this;
      vm.weixinInit();
    },
    mounted: function mounted() {
      var selectInfoItem = JSON.parse(this.$route.query.selectItem);
      selectInfoItem.seedingAtStr = this.formatDate(selectInfoItem.seedingAt, "y年m月").substring(2);
      selectInfoItem.harvestAtStr = this.formatDate(selectInfoItem.harvestAt, "y年m月").substring(2); // 设置投料分数

      this.userScoreOption.series[0].data[0].value = selectInfoItem.score; // 设置投料分数颜色

      if (selectInfoItem.riskLevel !== 1) {
        this.userScoreOption.series[0].itemStyle.normal.color.colorStops = [{
          offset: 0,
          color: "#FFB862"
        }, {
          offset: 1,
          color: "#F66E19"
        }];
      }

      this.selectInfoItem = selectInfoItem; // 图表

      var element = document.getElementById("score-chart"); // 设置图表容器高度

      element.setAttribute("style", "height:".concat(220 * 0.005 + "rem"));
      element && element.setAttribute("_echarts_instance_", ""); // 初始化图表

      var myScoreChart = this.$echarts.init(document.getElementById("score-chart"));
      myScoreChart.setOption(this.userScoreOption);
      this.getPondData(); // 摄像头

      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      this.year = year;
      this.month = month;
      this.chooseDay = day;
      var today = new Date();
      var currentMonth = today.getMonth();
      var currentYear = today.getFullYear();
      var daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      var firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
      var days = [];

      for (var i = 1; i <= firstDayOfMonth; i++) {
        days.push("");
      }

      for (var _i = 1; _i <= daysInMonth; _i++) {
        days.push(_i);
      } // if (!window.connector_live) {
      //   window.connector_live = new KP2PCONNECTOR.kp2pconnector();
      // }


      this.app = this.$root; // 假设你的Vue实例挂载在$root上，根据实际情况调整获取全局应用实例的方式
      // stream_id: 0高清，1流畅

      this.year = year;
      this.month = month;
      this.day = day;
      this.days = days;
      this.chooseDay = day;
      this.chooseMonth = month;
      this.daysInFirst = firstDayOfMonth;
      this.nowDate = new Date("".concat(year, "-").concat(month, "-").concat(day)).getTime();
    },
    methods: {
      // 格式化日期的辅助方法，模拟小程序中app.utils.getTime方法
      formatDate: function formatDate(time) {
        var formate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "y-m-d h:m:s";
        var res = "";

        if (time) {
          // 强制时间戳为13尾，获取时间单值
          "".concat(time).length == 10 && (time = Number(time) * 1000);
          var date = new Date(time);
          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          month = month >= 10 ? month : "0".concat(month);
          var day = date.getDate();
          day = day >= 10 ? day : "0".concat(day);
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var secnds = date.getSeconds();
          hours = hours >= 10 ? hours : "0".concat(hours);
          minutes = minutes >= 10 ? minutes : "0".concat(minutes);
          secnds = secnds >= 10 ? secnds : "0".concat(secnds); // 替换年月日

          var formateArr = formate.split(" ");
          res = formateArr[0].split("y").join(year);
          res = res.split("m").join(month);
          res = res.split("d").join(day); // 替换时分秒

          if (formateArr[1]) {
            res += " ".concat(formateArr[1].split("h").join(hours));
            res = res.split("m").join(minutes);
            res = res.split("s").join(secnds);
          }

          !year && (res = res.substr(1));
        }

        return res;
      },
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ["openLocation"]
          });
        });
      },
      // 获取鱼塘数据
      getPondData: function getPondData() {
        var _this = this;

        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.DEALER_WECHAT_BASE_URL, "riskAss/dayRecords"),
          headers: {
            "x-access-token": vm.token
          },
          params: {
            poolId: vm.selectInfoItem.poolId
          }
        }).then(function (response) {
          var res = response.data;
          res.avgDayFeedStr = (res.avgDayFeed / 500).toFixed(2);
          vm.$set(vm, "pondDataInfo", res);
          vm.ipcId = vm.pondDataInfo.ipcId; // 初始化溶氧曲线

          vm.initOxygenChart(res.ryDayRecordView); // 初始化投料分柱状图

          vm.initFeedChart(res.tlDayRecordView);

          _this.loadWarningPic();
        }).catch(function (error) {
          console.error(error);
        });
      },
      // 初始化溶氧曲线
      initOxygenChart: function initOxygenChart(chartData) {
        var vm = this;
        var oxygenChartOption = vm.oxygenChartOption;
        var oxygenList = chartData.map(function (item) {
          return item.oxygen;
        });
        var xAxisList = chartData.map(function (item) {
          return vm.formatDate(new Date(item.recordTime), "m/d");
        });
        oxygenChartOption.xAxis.data = xAxisList; // 获取溶氧列表的最大值

        var maxOxygen = Math.max.apply(null, oxygenList);
        oxygenChartOption.yAxis.interval = Math.ceil(Math.ceil(maxOxygen) / 2);
        oxygenChartOption.series = [{
          type: "line",
          smooth: true,
          data: oxygenList,
          itemStyle: {
            color: "#209A56"
          },
          symbol: "none",
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0.7,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: "#b1e1ca"
              }, {
                offset: 1,
                color: "#fff"
              }]
            }
          }
        }];
        this.$set(vm, "oxygenChartOption", oxygenChartOption);
        vm.setOxygenChart();
        vm.isloadChart = true;
      },
      // 更新溶氧曲线
      setOxygenChart: function setOxygenChart() {
        // 设置图表容器高度
        var element = document.getElementById("oxygen-chart");
        element.setAttribute("style", "height:".concat(340 * 0.005 + "rem"));
        element && element.setAttribute("_echarts_instance_", ""); // 初始化图表

        var myOxygenChart = this.$echarts.init(document.getElementById("oxygen-chart"));
        myOxygenChart.setOption(this.oxygenChartOption);
      },
      // 初始化投料分柱状图
      initFeedChart: function initFeedChart(chartData) {
        var vm = this;
        var feedChartOption = vm.feedChartOption;
        var oxygenList = chartData.map(function (item) {
          return item.dayFeed / 500;
        });
        var xAxisList = chartData.map(function (item) {
          var newDateStr = item.recordTime.replace(/[年月]/g, "-");
          var newStr = newDateStr.replace("日", "");
          return vm.formatDate(new Date(newStr), "m/d");
        });
        feedChartOption.yAxis.interval = Math.ceil((vm.pondDataInfo.avgDayFeedStr < 3000 ? 1500 : 5000) / 2);
        feedChartOption.xAxis.data = xAxisList;
        feedChartOption.series = [{
          data: oxygenList,
          type: "bar",
          itemStyle: {
            normal: {
              color: "#009E4E"
            }
          },
          emphasis: {
            itemStyle: {
              color: "#009E4E"
            }
          }
        }];
        this.$set(vm, "feedChartOption", feedChartOption);
        vm.setFeedChart();
      },
      // 更新投料分柱状图
      setFeedChart: function setFeedChart() {
        // 设置图表容器高度
        var element = document.getElementById("feed-chart");
        element.setAttribute("style", "height:".concat(340 * 0.005 + "rem"));
        element && element.setAttribute("_echarts_instance_", ""); // 初始化图表

        var myFeedChart = this.$echarts.init(document.getElementById("feed-chart"));
        myFeedChart.setOption(this.feedChartOption);
      },
      // 切换鱼塘数据顶导航
      onchangePondDataLabel: function onchangePondDataLabel(index) {
        var vm = this;
        this.pondDataLabel = index;

        if (index === 0 && vm.isloadChart) {
          this.$nextTick(function () {
            vm.setFeedChart();
            vm.setOxygenChart();
          });
        }
      },
      // 打开地图定位
      onclickOpenMap: function onclickOpenMap() {
        var _this$selectInfoItem = this.selectInfoItem,
            latitude = _this$selectInfoItem.latitude,
            longitude = _this$selectInfoItem.longitude,
            provinceName = _this$selectInfoItem.provinceName,
            cityName = _this$selectInfoItem.cityName,
            address = _this$selectInfoItem.address;
        var userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.indexOf('miniprogram') !== -1) {
          // 处于小程序环境，进行相应修改
          window.wx.openLocation({
            latitude: Number(latitude),
            longitude: Number(longitude),
            scale: 16,
            name: '鱼塘定位',
            address: "".concat(provinceName).concat(cityName).concat(address)
          });
          return;
        }

        this.$router.push({
          path: "/pondManage/mapPage",
          query: {
            mapUrl: "https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:".concat(latitude, ",").concat(longitude, ";title:\u9C7C\u5858\u5B9A\u4F4D;addr:").concat(provinceName).concat(cityName).concat(address, "&key=5G4BZ-D26RQ-XEI5R-2QSSO-EQ63T-ZVBNB&referer=tgjapp")
          }
        });
      },
      // 查看产量提示
      onclickViewYieldTip: function onclickViewYieldTip() {
        this.isShowYieldTip = !this.isShowYieldTip;
      },
      // 展开收起数据
      onclickShowAllData: function onclickShowAllData() {
        this.isShowAllData = !this.isShowAllData;
      },
      // *********摄像头********
      // 确定选择日期/时间段
      clickToChooseConfirm: function clickToChooseConfirm() {
        // const { chooseDay, timeSlotIndex, timeSlotStart, timeSlotEnd } = this;
        // 录像查询
        // this.$loading.show({
        //   text: '加载中',
        //   mask: true
        // });
        // let start = new Date(`${this.year}-${this.month}-${chooseDay} ${timeSlotIndex == 1? '00:00:00' : timeSlotStart}`).getTime() / 1000;
        // let end = new Date(`${this.year}-${this.month}-${chooseDay} ${timeSlotIndex == 1? '23:59:59' : timeSlotEnd}`).getTime() / 1000;
        // if (this.platform!= 'android') {
        //   start = new Date(`${this.year}/${this.month}/${chooseDay} ${timeSlotIndex == 1? '00:00:00' : timeSlotStart}`).getTime() / 1000;
        //   end = new Date(`${this.year}/${this.month}/${chooseDay} ${timeSlotIndex == 1? '23:59:59' : timeSlotEnd}`).getTime() / 1000;
        // }
        // TODO
        this.showChooseDaysRecordPopUp = false; // this.month = this.month;
        // this.year = this.year;

        this.day = this.chooseDay;
        this.loadWarningPic(); // this.$loading.hide();
      },
      // 点击选择查看录像的日期
      clickToChooseRecordDay: function clickToChooseRecordDay(chooseDay) {
        var year = this.year,
            month = this.month,
            nowDate = this.nowDate;

        if (new Date("".concat(year, "-").concat(month, "-").concat(chooseDay, " 00:00:00")).getTime() > nowDate) {
          (0, _toast.default)("不能选择今天之后的日期");
          return;
        }

        if (chooseDay) {
          this.chooseDay = chooseDay;
          this.chooseYear = year;
          this.chooseMonth = month;
        }
      },
      // 点击打开选择日期弹框
      showChooseRecordDatePop: function showChooseRecordDatePop() {
        this.showChooseDaysRecordPopUp = true;
      },
      // 点击蒙层取消选择日期弹窗
      clickCloseChooseDaysRecordPopUp: function clickCloseChooseDaysRecordPopUp() {
        this.showChooseDaysRecordPopUp = false;
      },
      // 点击进入上一个月的录像情况
      lastMonth: function lastMonth() {
        var month = parseInt(this.month);

        if (month == 1) {
          this.month = 12;
          this.year = this.year - 1;
        } else {
          if (month <= 10) {
            month = "0" + (month - 1);
            this.month = month;
          } else {
            this.month = month - 1;
          }
        }

        var daysOfMonth = new Date(this.year, this.month, 0).getDate();
        var daysOfFirst = new Date(this.year, this.month - 1, 1).getDay();
        var days = [];

        for (var i = 1; i <= daysOfFirst; i++) {
          days.push("");
        }

        for (var _i2 = 1; _i2 <= daysOfMonth; _i2++) {
          days.push(_i2);
        }

        this.days = days;
        this.daysInFirst = daysOfFirst;
      },
      // 点击进入下一个月的录像情况
      nextMonth: function nextMonth() {
        var month = parseInt(this.month);

        if (month == 12) {
          this.month = "01";
          this.year = this.year + 1;
        } else {
          if (month < 9) {
            month = "0" + (month + 1);
            this.month = month;
          } else {
            this.month = month + 1;
          }
        }

        var daysOfMonth = new Date(this.year, this.month, 0).getDate();
        var daysOfFirst = new Date(this.year, this.month - 1, 1).getDay();
        var days = [];

        for (var i = 1; i <= daysOfFirst; i++) {
          days.push("");
        }

        for (var _i3 = 1; _i3 <= daysOfMonth; _i3++) {
          days.push(_i3);
        }

        this.days = days;
        this.daysInFirst = daysOfFirst;
      },
      // 获取live组件视频流的地址
      onGetLiveUrl: function onGetLiveUrl(uuid, url) {
        this.url = url;
      },
      // 清空live组件的地址缓存
      cleanURLCache: function cleanURLCache() {
        window.connector_live.cleanUrlCache(this.ipcId);
      },
      onClickShowVideoBtn: function onClickShowVideoBtn() {
        window.connector_live.getLiveUrl(this.ipcId, "", 0, 1, this.app.appConfig.videoWebsocketHost, this.onGetLiveUrl);
        this.showVideoPopUp = true;
        this.videoLoading = 0; // 播放

        this.$refs.livePlayer.play();
      },
      // 视频流状态更新回调
      liveChange: function liveChange(e) {
        var successCodeArr = [2004, 2008]; // 视频流打开成功

        if (successCodeArr.includes(e.detail.code)) {
          // 只有在非回放状态且websocket未连接上的时候提示
          if (this.webSocketCallback == 1 && !this.socketTip) {
            this.$toast({
              message: "连接成功",
              icon: "success",
              duration: 2000
            });
            this.socketTip = true;
          }

          this.videoLoading = 1;
        } // 拉流：网络断连，且经多次重连无效，请自行重启拉流


        if (e.detail.code == -2301) {
          this.$loading.hide();
          this.$toast({
            message: "连接失败",
            icon: "error",
            duration: 2000
          });
          this.videoLoading = -1;
        }
      },
      reconnectIpcStream: function reconnectIpcStream() {
        // 重新获取视频流
        this.cleanURLCache();
        this.onClickShowVideoBtn();
      },
      onCloseVideoModal: function onCloseVideoModal() {
        this.showVideoPopUp = false;
        this.videoLoading = 0;
      },
      loadWarningPic: function loadWarningPic() {
        var _this2 = this;

        var vm = this;
        var ipcId = this.ipcId,
            month = this.month,
            day = this.day,
            year = this.year;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.DEALER_WECHAT_BASE_URL, "boss/lookPic"),
          headers: {
            "x-access-token": vm.token
          },
          params: {
            ipc: ipcId,
            date: "".concat(year).concat(month > 9 ? month : "0" + Number(month)).concat(day > 9 ? day : "0" + day)
          }
        }).then(function (response) {
          var res = response.data;
          var imgList = [];
          res.map(function (item) {
            var time = item.picTime.split("T")[1].split(".")[0].split(":");
            item.time = "".concat(year, "/").concat(month, "/").concat(day, " ").concat(time[0], ":").concat(time[1]);
            item.time = item.time.replace("202", "2");
            imgList.push(item.picUrl);
          });
          _this2.picList = res;
        }).catch(function (error) {
          console.error(error);
        });
      },
      previewImage: function previewImage(index, imgList) {
        var images = this.picList.map(function (item) {
          return item.picUrl;
        });
        imgList && (images = imgList);
        (0, _imagePreview.default)({
          images: images,
          startPosition: index
        });
      }
    }
  };
  _exports.default = _default;
});
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-index" }, [
    _c("div", { staticClass: "home-title" }, [
      _vm._v("塘管家鱼塘资产风控平台")
    ]),
    _c("div", { staticClass: "login-btn", on: { click: _vm.onclickToList } }, [
      _vm._v("登 录")
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-box" }, [
      _c("img", { attrs: { src: require("../../assets/yddlogo.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type != 6
      ? _c(
          "div",
          {
            class: {
              "component-quality-item": true,
              "tl-item": _vm.fType == 5 && !_vm.isLoss,
              "loss-item": _vm.fType == 5 && _vm.isLoss
            },
            on: { click: _vm.clickItem }
          },
          [
            _vm.fType == 5 && !_vm.isLoss
              ? _c("div", [
                  _c("img", {
                    attrs: { src: _vm.ossHost + "img/icon-tl-box.jpg", alt: "" }
                  }),
                  _c("div", { staticClass: "item-num" }, [
                    _vm._v(_vm._s(_vm.item.uniqueId))
                  ]),
                  _c("div", { staticClass: "item-info" }, [
                    _c("div", { staticClass: "info-name" }, [
                      _vm._v(_vm._s(_vm.item.operatorName))
                    ]),
                    _c("div", { staticClass: "info time" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formateTime")(_vm.item.submitAt, "m-d h:f")
                        )
                      )
                    ])
                  ])
                ])
              : _vm.fType == 5 && _vm.isLoss
              ? _c(
                  "div",
                  { staticClass: "loss-box" },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.ossHost + "img/icon-tl-box.jpg",
                        alt: ""
                      }
                    }),
                    _c("div", { staticClass: "item-num" }, [
                      _vm._v(_vm._s(_vm.item.uniqueId))
                    ]),
                    _c("Icon", {
                      staticClass: "item-arrow",
                      attrs: { name: "arrow" }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("div", { staticClass: "item-title" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.ossHost + "img/icon-device.png",
                          alt: ""
                        }
                      }),
                      _vm._v(" " + _vm._s(_vm.item.uniqueId) + " ")
                    ]),
                    _vm.item.status == 1
                      ? _c("div", { staticClass: "item-time" }, [
                          _vm._v(_vm._s(_vm._f("getYMD")(_vm.item.createAt)))
                        ])
                      : _vm._e(),
                    _vm.item.status == 2
                      ? _c("div", { staticClass: "item-time" }, [
                          _vm._v(_vm._s(_vm._f("getYMD")(_vm.item.createAt)))
                        ])
                      : _vm._e(),
                    _vm.item.status == 3
                      ? _c("div", { staticClass: "item-time" }, [
                          _vm._v(_vm._s(_vm._f("getYMD")(_vm.item.transactAt)))
                        ])
                      : _vm._e(),
                    _vm.item.status == 4
                      ? _c("div", { staticClass: "item-time" }, [
                          _vm._v(_vm._s(_vm._f("getYMD")(_vm.item.finishAt)))
                        ])
                      : _vm._e(),
                    _vm.item.status == 5
                      ? _c("div", { staticClass: "item-time" }, [
                          _vm._v(_vm._s(_vm._f("getYMD")(_vm.item.finishAt)))
                        ])
                      : _vm._e(),
                    _c("Icon", { attrs: { name: "arrow" } }),
                    _c("span", { staticClass: "item-status" }, [
                      _vm._v(
                        _vm._s(_vm._f("filterOrderStatus")(_vm.item.status))
                      )
                    ])
                  ],
                  1
                )
          ]
        )
      : _vm._e(),
    _vm.type == 6
      ? _c(
          "div",
          { staticClass: "device-concise-item", on: { click: _vm.clickItem } },
          [
            _c("div", { staticClass: "item-info" }, [
              _vm.item.deviceBaseType == 5
                ? _c("img", {
                    attrs: {
                      src: _vm.ossHostPond + "img/icon_water_box.png",
                      alt: ""
                    }
                  })
                : _vm._e(),
              _vm.item.deviceBaseType == 6
                ? _c("img", {
                    attrs: { src: _vm.ossHostPond + "img/icp.jpeg", alt: "" }
                  })
                : _vm._e(),
              _c("div", { staticClass: "item-num" }, [
                _vm._v(_vm._s(_vm.item.uniqueId))
              ])
            ]),
            _c("Icon", { staticClass: "item-arrow", attrs: { name: "arrow" } })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
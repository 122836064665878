var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pond-monitoring-page" },
    [
      _c("div", { staticClass: "content user-info" }, [
        _c("div", { staticClass: "user-info-header" }, [
          _c("div", { staticClass: "score-chart-back" }, [
            _c("div", { staticClass: "score-chart-box" }, [
              _c("div", {
                style: 220 * 0.005 + "rem",
                attrs: { id: "score-chart" }
              })
            ]),
            _c(
              "div",
              {
                staticClass: "header-score",
                class: { yellow: _vm.selectInfoItem.riskLevel !== 1 }
              },
              [_vm._v(" " + _vm._s(_vm.selectInfoItem.score) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "header-start",
                class: { yellow: _vm.selectInfoItem.riskLevel !== 1 }
              },
              [
                _vm.selectInfoItem.riskLevel === 1
                  ? _c("span", [_vm._v("低风险")])
                  : _vm._e(),
                _vm.selectInfoItem.riskLevel === 2
                  ? _c("span", [_vm._v("中风险")])
                  : _vm._e(),
                _vm.selectInfoItem.riskLevel === 3
                  ? _c("span", [_vm._v("高风险")])
                  : _vm._e()
              ]
            )
          ]),
          _c("div", { staticClass: "info-list" }, [
            _c("div", [_vm._v("姓名：" + _vm._s(_vm.selectInfoItem.name))]),
            _c("div", [
              _vm._v("鱼塘产证号：" + _vm._s(_vm.selectInfoItem.idCard))
            ]),
            _c("div", [
              _vm._v(
                " 地区：" +
                  _vm._s(_vm.selectInfoItem.provinceName) +
                  _vm._s(_vm.selectInfoItem.cityName) +
                  " "
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "user-info-tip" }, [
          _c("div", { staticClass: "title" }, [_vm._v("近期总结")]),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.selectInfoItem.summary))
          ]),
          _vm.selectInfoItem.riskLevel === 1
            ? _c("div", { staticClass: "tip" }, [
                _vm._v(" 当前整体状况平稳，风险较低。 ")
              ])
            : _c("div", { staticClass: "tip" }, [
                _vm._v(
                  " 当前整体状况有待优化，已通过小程序和语音电话提醒用户整改。 "
                )
              ])
        ])
      ]),
      _c("div", { staticClass: "content pond-info" }, [
        _c("div", { staticClass: "pond-info-item header" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", [_vm._v("预估总产量")]),
            _c(
              "div",
              {
                staticClass: "header-icon",
                on: { click: _vm.onclickViewYieldTip }
              },
              [_vm._v("?")]
            )
          ]),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.selectInfoItem.estimatedOutput) + "斤")
          ])
        ]),
        _c("div", { staticClass: "pond-info-item" }, [
          _c("div", { staticClass: "left" }, [_vm._v("养殖鱼种")]),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.selectInfoItem.fishName))
          ])
        ]),
        _c("div", { staticClass: "pond-info-item" }, [
          _c("div", { staticClass: "left" }, [_vm._v("尾数")]),
          _c("div", { staticClass: "right" }, [
            _vm._v(_vm._s(_vm.selectInfoItem.density) + "尾/亩")
          ])
        ]),
        _c(
          "div",
          { staticClass: "pond-info-item", on: { click: _vm.onclickOpenMap } },
          [
            _c("div", { staticClass: "left" }, [_vm._v("鱼塘位置")]),
            _c("div", { staticClass: "right" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.selectInfoItem.provinceName) +
                  _vm._s(_vm.selectInfoItem.cityName) +
                  _vm._s(_vm.selectInfoItem.address) +
                  " "
              ),
              _c("img", {
                staticClass: "map-icon",
                attrs: { src: _vm.ossHost + "img/icon-store-map.png" }
              })
            ])
          ]
        ),
        _vm.isShowAllData
          ? _c("div", [
              _c("div", { staticClass: "pond-info-item" }, [
                _c("div", { staticClass: "left" }, [_vm._v("鱼塘面积")]),
                _c("div", { staticClass: "right" }, [
                  _vm._v(_vm._s(_vm.selectInfoItem.poolSize) + "亩")
                ])
              ]),
              _c("div", { staticClass: "pond-info-item" }, [
                _c("div", { staticClass: "left" }, [_vm._v("下塘日期")]),
                _c("div", { staticClass: "right" }, [
                  _vm._v(_vm._s(_vm.selectInfoItem.seedingAtStr))
                ])
              ]),
              _c("div", { staticClass: "pond-info-item" }, [
                _c("div", { staticClass: "left" }, [_vm._v("预计出塘日期")]),
                _c("div", { staticClass: "right" }, [
                  _vm._v(_vm._s(_vm.selectInfoItem.harvestAtStr))
                ])
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "pond-info-btn",
            on: { click: _vm.onclickShowAllData }
          },
          [
            _vm._v(" " + _vm._s(_vm.isShowAllData ? "收起" : "展开") + " "),
            _c("div", {
              staticClass: "arrow",
              class: { invert: _vm.isShowAllData }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "content pond-data" }, [
        _c("div", { staticClass: "label" }, [
          _c(
            "div",
            {
              staticClass: "label-item",
              class: { active: _vm.pondDataLabel === 0 },
              on: {
                click: function($event) {
                  return _vm.onchangePondDataLabel(0)
                }
              }
            },
            [_vm._v(" 环境数据 ")]
          ),
          _c(
            "div",
            {
              staticClass: "label-item",
              class: { active: _vm.pondDataLabel === 1 },
              on: {
                click: function($event) {
                  return _vm.onchangePondDataLabel(1)
                }
              }
            },
            [_vm._v(" 监控截图 ")]
          ),
          _c(
            "div",
            {
              staticClass: "label-item",
              class: { active: _vm.pondDataLabel === 2 },
              on: {
                click: function($event) {
                  return _vm.onchangePondDataLabel(2)
                }
              }
            },
            [_vm._v(" 事件记录 ")]
          )
        ]),
        _vm.pondDataLabel === 0
          ? _c("div", { staticClass: "pond-data-main" }, [
              _c("div", { staticClass: "oxygen-info-box" }, [
                _c("div", { staticClass: "oxygen-score" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("溶氧分")]),
                  _c(
                    "div",
                    {
                      staticClass: "score",
                      class: { yellow: _vm.pondDataInfo.ryScore < 60 }
                    },
                    [_vm._v(" " + _vm._s(_vm.pondDataInfo.ryScore) + " ")]
                  )
                ]),
                _c("div", { staticClass: "oxygen-chart-box" }, [
                  _c("div", {
                    style: 340 * 0.005 + "rem",
                    attrs: { id: "oxygen-chart" }
                  })
                ]),
                _c("div", { staticClass: "oxygen-tip" }, [
                  _vm._v(_vm._s(_vm.pondDataInfo.rySummary))
                ])
              ]),
              _c("div", { staticClass: "dashed-line" }),
              _c("div", { staticClass: "feed-info-box" }, [
                _c("div", { staticClass: "feed-item" }, [
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("投料分")]),
                    _c(
                      "div",
                      {
                        staticClass: "score",
                        class: { yellow: _vm.pondDataInfo.tlScore < 60 }
                      },
                      [_vm._v(" " + _vm._s(_vm.pondDataInfo.tlScore) + " ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "feed-item" }, [
                  _c("div", [_vm._v("近一周日均投料")]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.pondDataInfo.avgDayFeedStr) + "斤")
                  ])
                ]),
                _c("div", { staticClass: "feed-chart-box" }, [
                  _c("div", {
                    style: 340 * 0.005 + "rem",
                    attrs: { id: "feed-chart" }
                  })
                ]),
                _c("div", { staticClass: "feed-tip" }, [
                  _vm._v(_vm._s(_vm.pondDataInfo.tlSummary))
                ])
              ])
            ])
          : _vm._e(),
        _vm.pondDataLabel === 1
          ? _c("div", { staticClass: "pond-data-main" }, [
              _c(
                "div",
                { staticClass: "ec-pond-monitor" },
                [
                  _c("div", { staticClass: "ec-header" }, [
                    _c("div"),
                    _c("div", { on: { click: _vm.showChooseRecordDatePop } }, [
                      _c("div", { staticClass: "record-up-days" }, [
                        _vm._v(
                          _vm._s(_vm.month) + "月" + _vm._s(_vm.day) + "日"
                        )
                      ]),
                      _c("img", {
                        staticClass: "record-up-image",
                        attrs: {
                          src: _vm.ossHost + "img/icon_calendar.png",
                          alt: "日历图标"
                        }
                      })
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "ec-warning-pic-list" },
                    _vm._l(_vm.picList, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "pic-box",
                          on: {
                            click: function($event) {
                              return _vm.previewImage(index)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: item.picUrl, alt: "图片" }
                          })
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "Popup",
                    {
                      staticClass: "choose-days-popup",
                      attrs: { position: "bottom", round: true },
                      on: {
                        "click-overlay": _vm.clickCloseChooseDaysRecordPopUp
                      },
                      model: {
                        value: _vm.showChooseDaysRecordPopUp,
                        callback: function($$v) {
                          _vm.showChooseDaysRecordPopUp = $$v
                        },
                        expression: "showChooseDaysRecordPopUp"
                      }
                    },
                    [
                      _c("div", { staticClass: "choose-days-bg" }, [
                        _c("div", { staticClass: "up-bg" }, [
                          _c("div", { staticClass: "choose-days-text" }, [
                            _vm._v("选择日期")
                          ]),
                          _c("div", { staticClass: "days-arrow-bg" }, [
                            !(_vm.year === 2025 && _vm.month === 1)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "left-arrow-box",
                                    on: { click: _vm.nextMonth }
                                  },
                                  [_c("div", { staticClass: "left-arrow" })]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "year-month-text" }, [
                              _vm._v(_vm._s(_vm.year) + "-" + _vm._s(_vm.month))
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "right-arrow-box",
                                on: { click: _vm.lastMonth }
                              },
                              [_c("div", { staticClass: "right-arrow" })]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "week-day" }, [
                          _c("div", [_vm._v("日")]),
                          _c("div", [_vm._v("一")]),
                          _c("div", [_vm._v("二")]),
                          _c("div", [_vm._v("三")]),
                          _c("div", [_vm._v("四")]),
                          _c("div", [_vm._v("五")]),
                          _c("div", [_vm._v("六")])
                        ]),
                        _c(
                          "div",
                          { staticClass: "days-on-month" },
                          _vm._l(_vm.days, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "days-item",
                                class: {
                                  choose:
                                    item === _vm.chooseDay &&
                                    _vm.month === _vm.chooseMonth
                                },
                                attrs: { "data-item": item },
                                on: {
                                  click: function($event) {
                                    return _vm.clickToChooseRecordDay(item)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "choose-btn",
                            on: { click: _vm.clickToChooseConfirm }
                          },
                          [_vm._v("确定")]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.pondDataLabel === 2
          ? _c("div", { staticClass: "pond-data-main" }, [
              _c(
                "div",
                { staticClass: "event-data-body" },
                _vm._l(
                  _vm.ipcId == "5864709874"
                    ? _vm.pond01ImgList
                    : _vm.pond02ImgList,
                  function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "event-item" },
                      [
                        _c("div", { staticClass: "item-title" }, [
                          _vm._v(_vm._s(item.date) + " " + _vm._s(item.type))
                        ]),
                        _c(
                          "div",
                          { staticClass: "event-img-list" },
                          _vm._l(item.images, function(sItem, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "pic-box",
                                on: {
                                  click: function($event) {
                                    return _vm.previewImage(index, item.images)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: { src: sItem, alt: "图片" }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  }
                ),
                0
              )
            ])
          : _vm._e()
      ]),
      _c(
        "Popup",
        {
          staticClass: "yield-popup",
          attrs: { round: "", "custom-style": { top: "40%" } },
          model: {
            value: _vm.isShowYieldTip,
            callback: function($$v) {
              _vm.isShowYieldTip = $$v
            },
            expression: "isShowYieldTip"
          }
        },
        [
          _c("div", { staticClass: "yield-body" }, [
            _c("div", { staticClass: "title" }, [_vm._v("预估产量")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " 根据历史溶氧情况推断饵料系数，并根据近期平均投料量计算得出 "
              )
            ]),
            _c(
              "div",
              {
                staticClass: "btn-item",
                on: { click: _vm.onclickViewYieldTip }
              },
              [_vm._v("我知道了")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/monitBg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-index[data-v-7057fb7e] {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n}\n.page-index .home-title[data-v-7057fb7e] {\n  width: 100%;\n  height: 0.5rem;\n  font-size: 0.3rem;\n  text-align: center;\n  line-height: 0.5rem;\n  position: relative;\n  top: 1.35rem;\n  left: 50%;\n  transform: translate(-50%, 0);\n  letter-spacing: 0.05rem;\n  font-weight: bold;\n  color: #fff;\n  width: 2.5rem;\n}\n.page-index .login-btn[data-v-7057fb7e] {\n  height: 0.6rem;\n  width: 2.3rem;\n  background: #fff;\n  font-size: 0.2rem;\n  color: #209A56;\n  border-radius: 0.7rem;\n  text-align: center;\n  line-height: 0.6rem;\n  margin: 0 auto;\n  position: relative;\n  top: 55%;\n}\n.page-index .logo-box[data-v-7057fb7e] {\n  position: absolute;\n  bottom: 0.1rem;\n  left: 50%;\n  transform: translate(-50%, 0);\n}\n.page-index .logo-box img[data-v-7057fb7e] {\n  width: auto;\n  height: 0.33rem;\n}\n", ""]);
// Exports
module.exports = exports;

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-page" }, [
    _c("iframe", {
      staticStyle: { border: "none" },
      attrs: {
        src: _vm.mapUrl,
        width: "100%",
        height: "100%",
        frameborder: "0"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
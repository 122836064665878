require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/esm/defineProperty", "vant/es/image-preview/style", "vant/es/image-preview", "vant/es/popup/style", "vant/es/popup", "vant/es/action-sheet/style", "vant/es/action-sheet", "vant/es/uploader/style", "vant/es/uploader", "vant/es/field/style", "vant/es/field", "@/utils/time", "@/lib/compressImage"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/esm/defineProperty"), require("vant/es/image-preview/style"), require("vant/es/image-preview"), require("vant/es/popup/style"), require("vant/es/popup"), require("vant/es/action-sheet/style"), require("vant/es/action-sheet"), require("vant/es/uploader/style"), require("vant/es/uploader"), require("vant/es/field/style"), require("vant/es/field"), require("@/utils/time"), require("@/lib/compressImage"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.defineProperty, global.style, global.imagePreview, global.style, global.popup, global.style, global.actionSheet, global.style, global.uploader, global.style, global.field, global.time, global.compressImage);
    global.qualityOrderInfo = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _defineProperty2, _style3, _imagePreview, _style4, _popup, _style5, _actionSheet, _style6, _uploader, _style7, _field, _time, _compressImage) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _defineProperty2 = _interopRequireDefault(_defineProperty2);
  _imagePreview = _interopRequireDefault(_imagePreview);
  _popup = _interopRequireDefault(_popup);
  _actionSheet = _interopRequireDefault(_actionSheet);
  _uploader = _interopRequireDefault(_uploader);
  _field = _interopRequireDefault(_field);
  var _default = {
    name: "FactoryQualityOrderInfo",
    filters: {
      getYMD: _time.getYMD
    },
    data: function data() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var type = 0;
      var isEdit = Number(vm.$route.query.type); // 1乐行质检，2览宋质检

      var showType = Number(vm.$route.query.showType);
      var responsibilityList = [{
        name: "览宋",
        id: 5
      }];
      var uuid = vm.$localStorage.getItem("uuid");
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (fType == 'ruiyin') {
        responsibilityList.unshift({
          name: "瑞银",
          id: 2
        });
        type = 1;
      }

      if (fType == 'lexing' || fType == 'wangbo') {
        responsibilityList.unshift({
          name: "旺博",
          id: 3
        });
        type = 2;
      }

      if (fType == 'haopai') {
        responsibilityList.unshift({
          name: "豪派",
          id: 6
        });
        type = 6;
      }

      return {
        info: {
          reason: ''
        },
        name: name,
        warningType: {
          show: false
        },
        actionMethods: {
          show: false
        },
        responsibilityObj: {
          show: false
        },
        tagList: [],
        type: type,
        methodsList: [{
          name: "整机翻新",
          id: 1
        }, {
          name: "报废(无需质检)",
          id: 2
        }, {
          name: "报废(需要质检)",
          id: 3
        }],
        responsibilityList: responsibilityList,
        remark: '',
        fileList: [],
        isEdit: isEdit,
        isShowPopup: false,
        reason: '',
        showType: showType
      };
    },
    components: (0, _defineProperty2.default)({
      Field: _field.default,
      Uploader: _uploader.default,
      ActionSheet: _actionSheet.default,
      Popup: _popup.default
    }, _imagePreview.default.Component.name, _imagePreview.default.Component),
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem("uuid");
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (!name || vm.$config.base.fList.indexOf(fType) == -1) {
        vm.$router.replace({
          path: "/factory/login"
        });
        return;
      }

      vm.loadDetail();
    },
    methods: {
      handleChange: function handleChange() {},
      loadDetail: function loadDetail() {
        var vm = this;
        vm.ttid = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/detail"),
          params: {
            uniqueId: vm.$route.params.id
          }
        }).then(function (res) {
          if (res.data) {
            var dType = vm.$strTool.resetDeviceType(res.data.deviceType);
            var imgs = [];
            vm.info = res.data; // 控制器增加处理方法

            if (dType == 1) {
              vm.methodsList.push({
                id: 5,
                name: '报废(翻新出厂质检不过)'
              });
            }

            if (vm.info.status > 1) {
              vm.info.factoryTag && (vm.warningType.name = vm.info.factoryTag || '暂无');
              vm.name = vm.info.factoryOperator;

              if (vm.info.handlingMethod) {
                var aItem = vm.methodsList.filter(function (m) {
                  return m.id == vm.info.handlingMethod;
                })[0];
                aItem && (vm.actionMethods.name = aItem.name);
                vm.actionMethods.id = vm.info.handlingMethod;
              }

              if (vm.info.responsibleParty) {
                var list = [{
                  name: "览宋",
                  id: 5
                }, {
                  name: "瑞银",
                  id: 2
                }, {
                  name: "旺博",
                  id: 3
                }];
                var rItem = list.filter(function (r) {
                  return r.id == vm.info.responsibleParty;
                })[0];
                rItem && (vm.responsibilityObj.name = rItem.name);
                vm.responsibilityObj.id = vm.info.responsibleParty;
              }

              vm.remark = vm.info.factoryRemark;
              vm.info.images && vm.info.images.map(function (item) {
                imgs.push({
                  url: item
                });
              });
              vm.fileList = imgs;
            }

            vm.loadTagList(res.data.deviceType);
          } else {
            _toast.default.fail({
              message: "设备编码不存在~",
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }

          vm.ttid.clear();
        }).catch(function (err) {
          console.log(err);
          vm.ttid.clear();
        });
      },
      loadTagList: function loadTagList(type) {
        var vm = this;
        var dType = vm.$strTool.resetDeviceType(type);
        var paramsType = dType == 1 ? 4 : 5;
        dType == 2 && (paramsType = 16); // 摄像头

        dType == 6 && (paramsType = 19); // 测水盒

        type == 12 && (paramsType = 18);
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/tags"),
          params: {
            type: paramsType
          }
        }).then(function (res) {
          if (res.data.length) {
            res.data.map(function (item) {
              item.name = item.tagName;

              if (vm.info.factoryTag && vm.isEdit && vm.info.factoryTag == item.tagName) {
                vm.warningType.id = item.id;
              }
            });
          }

          vm.tagList = res.data;
        }).catch(function (err) {
          console.log(err);
        });
      },
      onclickPopBack: function onclickPopBack() {
        this.isShowPopup = false;
        this.warningType.show = true;
      },
      onclickClosePop: function onclickClosePop() {
        var vm = this;

        if (!vm.reason) {
          (0, _toast.default)('请填写问题描述');
          return;
        }

        vm.isShowPopup = false;
      },
      afterRead: function afterRead(file) {
        var vm = this;
        file.status = 'uploading';
        file.message = '上传中...';
        (0, _compressImage.compressImage)(file.content, {
          width: 500,
          quality: 0.1
        }).then(function (res) {
          // vm.uploadImg(convertBase64UrlToBlob(res), file);
          vm.uploadImg(res, file);
        }); // vm.uploadImg(file)
      },
      uploadImg: function uploadImg(file, upFile) {
        var vm = this; // const f = new FormData();
        // f.append('file', file.file)
        // alert(JSON.stringify(file))

        vm.$http({
          type: "post",
          url: "".concat(vm.$config.base.ADMIN_URL, "javaApi/file/iot/qcImages?password=").concat(vm.$config.base.password),
          // data: { file: f }
          data: {
            encodeFile: file.split('base64,')[1],
            ext: file.split(';base64')[0].split('image/')[1]
          }
        }).then(function (res) {
          upFile.url = res.data;
          upFile.status = 'succ';
          upFile.message = '';
        }).catch(function (err) {
          console.log(err);
        });
      },
      selectWarning: function selectWarning(obj) {
        var vm = this;
        vm.warningType = Object.assign({}, vm.warningType, obj);
        vm.isShowPopup = obj.tagName == '其他';
      },
      selectMethods: function selectMethods(obj) {
        var vm = this;
        vm.actionMethods = Object.assign({}, vm.actionMethods, obj);
      },
      selectResponsibility: function selectResponsibility(obj) {
        var vm = this;
        vm.responsibilityObj = Object.assign({}, vm.responsibilityObj, obj);
      },
      selectItem: function selectItem(type) {
        var vm = this;
        type == 'w' && (vm.warningType.show = true);
        type == 'c' && (vm.actionMethods.show = true);
        type == 'z' && (vm.responsibilityObj.show = true);
      },
      submitOrder: function submitOrder() {
        var vm = this;
        var error = '';
        var imgList = [];
        vm.fileList.length && vm.fileList.map(function (file) {
          file.url && imgList.push(file.url);
        });
        !vm.responsibilityObj.id && (error = '请选择责任人');
        !vm.actionMethods.id && (error = '请选择处理方法');
        !vm.warningType.id && (error = '请选择故障类型');
        vm.warningType.tagName == '其他' && !vm.reason && error;
        !imgList.length && (error = '请上传图片');

        if (error) {
          _toast.default.fail({
            message: error,
            forbidClick: true,
            overlay: true,
            duration: 2000
          });

          return;
        }

        var params = {
          id: vm.info.id,
          operator: vm.name,
          password: vm.$config.base.password,
          remark: vm.remark,
          responsibleParty: vm.responsibilityObj.id,
          tagId: vm.warningType.id,
          handlingMethod: vm.actionMethods.id,
          reason: vm.reason,
          images: imgList
        };

        if (vm.info.status > 1 && vm.isEdit && vm.type == 1) {
          _dialog.default.confirm({
            title: "是否确认提交?",
            message: "未完成结算的订单只支持修改一次质检记录",
            confirmButtonColor: "#32BE32",
            confirmButtonText: "确认提交"
          }).then(function () {
            vm.tt = _toast.default.loading({
              message: "加载中...",
              forbidClick: true,
              overlay: true,
              duration: 0
            });
            vm.$http({
              method: "put",
              url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac").concat(vm.info.status > 1 ? '/checkInfo' : ''),
              data: params
            }).then(function (res) {
              vm.tt.clear();

              if (res.code != 1) {
                _toast.default.fail({
                  message: res.message,
                  forbidClick: true,
                  overlay: true,
                  duration: 2000
                });

                return;
              }

              _toast.default.success({
                message: vm.info.status > 1 ? '已修改质检单' : '成功提交质检单，请耐心等待览宋确认',
                forbidClick: true,
                overlay: true,
                duration: 2000
              });

              setTimeout(function () {
                vm.$router.replace({
                  path: "/factory/quality/history/".concat(vm.$route.params.id, "?id=").concat(vm.info.id)
                });
              }, 2000);
            }).catch(function (err) {
              console.log(err);
              vm.tt.clear();
            });
          }).catch(function () {});

          return;
        }

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认上传定损记录?",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          vm.tt = _toast.default.loading({
            message: "加载中...",
            forbidClick: true,
            overlay: true,
            duration: 0
          });
          vm.$http({
            method: "put",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac").concat(vm.info.status > 1 ? '/checkInfo' : ''),
            data: params
          }).then(function (res) {
            vm.tt.clear();

            if (res.code != 1) {
              _toast.default.fail({
                message: res.message,
                forbidClick: true,
                overlay: true,
                duration: 2000
              });

              return;
            }

            _toast.default.success({
              message: vm.info.status > 1 ? '已修改质检单' : '成功提交质检单，请耐心等待览宋确认',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });

            setTimeout(function () {
              vm.$router.replace({
                path: "/factory/quality/history/".concat(vm.$route.params.id, "?id=").concat(vm.info.id)
              });
            }, 2000);
          }).catch(function (err) {
            console.log(err);
            vm.tt.clear();
          });
        }).catch(function () {// on cancel
        });
      },
      previewImg: function previewImg(index) {
        var vm = this;
        (0, _imagePreview.default)({
          images: vm.info.images,
          startPosition: index
        });
      }
    }
  };
  _exports.default = _default;
});
require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "vant/es/field/style", "vant/es/field", "vant/es/popup/style", "vant/es/popup", "vant/es/step/style", "vant/es/step", "vant/es/steps/style", "vant/es/steps", "@/utils/str", "@/utils/time"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/field/style"), require("vant/es/field"), require("vant/es/popup/style"), require("vant/es/popup"), require("vant/es/step/style"), require("vant/es/step"), require("vant/es/steps/style"), require("vant/es/steps"), require("@/utils/str"), require("@/utils/time"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.style, global.field, global.style, global.popup, global.style, global.step, global.style, global.steps, global.str, global.time);
    global.qualityHistory = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _style3, _field, _style4, _popup, _style5, _step, _style6, _steps, _str, _time) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _field = _interopRequireDefault(_field);
  _popup = _interopRequireDefault(_popup);
  _step = _interopRequireDefault(_step);
  _steps = _interopRequireDefault(_steps);
  var _default = {
    name: "RyQualityHistory",
    data: function data() {
      var vm = this;
      var _vm$$config$base = vm.$config.base,
          ossHost = _vm$$config$base.ossHost,
          ossHostPond = _vm$$config$base.ossHostPond;
      var uuid = vm.$localStorage.getItem("uuid");
      var fType = vm.$strTool.filterFactoryType(uuid);
      return {
        ossHost: ossHost,
        ossHostPond: ossHostPond,
        info: {},
        canvasWith: window.screen.width - 60,
        isRuiy: fType == 'ruiyin',
        barCode: '',
        isShowPopup: false,
        isShowRecord: false,
        deviceType: ''
      };
    },
    components: {
      Steps: _steps.default,
      Step: _step.default,
      Popup: _popup.default,
      Field: _field.default
    },
    filters: {
      filterOrderStatus: _str.filterOrderStatus,
      getYMD: _time.getYMD
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.fType = fType;

      if (!name) {
        vm.$router.replace({
          path: "/factory/login"
        });
        return;
      }

      if (vm.$config.base.fList.indexOf(fType) == -1) {
        vm.$router.replace({
          path: "/factory/login"
        });
        return;
      }

      vm.loadDetail();
    },
    methods: {
      loadDetail: function loadDetail() {
        var vm = this;
        var params = {};
        var url = "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/").concat(vm.$route.query.id, "/detail");
        vm.ttid = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });

        if (vm.fType != 'ruiyin') {
          params.uniqueId = vm.$route.params.id;
          url = "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/detail");
        }

        vm.$http({
          type: "get",
          url: url,
          params: params
        }).then(function (res) {
          if (res.data) {
            res.data.flow && res.data.flow.length && res.data.flow.reverse();
            vm.info = res.data;
            vm.info.dType = vm.$strTool.resetDeviceType(res.data.deviceType);
            vm.barCode = res.data.mainboardId;
            vm.deviceType = window.deviceTypes.filter(function (item) {
              return item.type == res.data.deviceType;
            })[0].alias;
            console.log(vm.deviceType);
            vm.loadWarningList();
          } else {
            _toast.default.fail({
              message: '设备编码不存在~',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }

          vm.ttid.clear();
        }).catch(function (err) {
          console.log(err);
          vm.ttid.clear();
        });
      },
      viewOrderInfo: function viewOrderInfo(type, showType) {
        this.$router.push({
          path: "/factory/quality/info/".concat(this.$route.params.id, "?type=").concat(type, "&showType=").concat(showType)
        });
      },
      loadWarningList: function loadWarningList() {
        var vm = this; // 豪派（摄像头/测水盒）无需调用离线次数

        if (vm.info.dType == 6 || vm.info.deviceType == 12) {
          return;
        }

        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "monthWarning"),
          params: {
            uniqueId: vm.$route.params.id
          }
        }).then(function (res) {
          var element = document.getElementById('warning-chart'); // 设置图表容器高度

          element.setAttribute('style', "height:".concat(res.data.length * 50, "px"));
          element && element.setAttribute('_echarts_instance_', '');

          try {
            var myChart = vm.$echarts.init(document.getElementById("warning-chart"));
            var xData = [];
            var yData = [];
            var colors = ['#E00906', '#FF9E20', '#FFD700', '#F5EA14'];

            if (res.data) {
              res.data.sort(function (a, b) {
                return a.size - b.size;
              });
              res.data.map(function (item) {
                xData.push((0, _str.filterWarningTypeText)(item.warningType));
                yData.push(item.size);
              });
            }

            myChart.setOption({
              height: res.data.length * 50 + 'px',
              xAxis: {
                type: 'value',
                show: false
              },
              yAxis: [{
                data: xData,
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                axisLabel: {
                  verticalAlign: 'bottom',
                  align: 'left',
                  padding: [0, 0, 10, 10],
                  textStyle: {
                    color: '#000',
                    fontSize: '16'
                  }
                }
              }, {
                axisLine: {
                  show: false
                },
                axisTick: {
                  show: false
                },
                data: yData,
                axisLabel: {
                  show: true,
                  verticalAlign: 'bottom',
                  align: 'right',
                  padding: [0, 10, 10, 0],
                  textStyle: {
                    color: '#000',
                    fontSize: '16'
                  }
                }
              }],
              series: [{
                name: '数量',
                type: 'bar',
                barWidth: 18,
                data: yData,
                barCategoryGap: 0,
                itemStyle: {
                  color: function color(params) {
                    var index = res.data.length - 1 - params.dataIndex;
                    return colors[index] || '#F5EA14';
                  }
                }
              }],
              grid: {
                top: '10',
                left: '0',
                right: '6'
              }
            });
          } catch (error) {
            console.log(error);
          }

          console.log(res);
        }).catch(function (err) {
          console.log(err);
        });
      },
      onclickClosePop: function onclickClosePop(type) {
        var vm = this; // 取消关联

        if (!type) {
          vm.barCode = '';
          vm.isShowPopup = false;
          return;
        } // 确定关联


        vm.tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        vm.$http({
          type: "put",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/mainboardId"),
          data: {
            password: vm.$config.base.password,
            // operator: vm.name,
            mainboardId: vm.barCode,
            id: vm.info.id
          },
          except: true
        }).then(function (res) {
          var code = res.code,
              message = res.message;
          vm.tt.clear();

          if (code == 1) {
            _toast.default.success({
              message: vm.info.mainboardId ? '修改成功' : '关联成功',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });

            vm.isShowPopup = false;
            setTimeout(vm.loadDetail, 2000);
          } else {
            _toast.default.fail({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }
        }).catch(function () {
          vm.tt.clear();
        });
      },
      // type: 1修改关联，0无关联的关联
      updateBarCode: function updateBarCode(type) {
        this.barCode = !type ? '' : this.info.mainboardId;
        this.isShowPopup = true;
      },
      onClickScanIcon: function onClickScanIcon() {
        var vm = this;
        window.wx.scanQRCode({
          needResult: 1,
          scanType: ["barCode"],
          success: function success(res) {
            if (!(res.resultStr && res.resultStr.length >= 10)) {
              _dialog.default.confirm({
                title: "提示",
                message: "非设备主板码~",
                confirmButtonColor: "#32BE32",
                showCancelButton: false
              });
            } else {
              vm.barCode = res.resultStr.split(',').length > 1 ? res.resultStr.split(',')[1] : res.resultStr; // vm.barCode = res.resultStr
            }
          }
        });
      },
      editOrder: function editOrder() {
        var vm = this;
        vm.$router.push({
          path: "/factory/quality/info/".concat(vm.$route.params.id, "?id=").concat(vm.info.id, "&ed=1&type=1")
        });
      },
      // 超年限报废处理
      onclickSubmitBtn: function onclickSubmitBtn() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;
        var operator = vm.$localStorage.getItem(USER_NAME);
        vm.tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        vm.$http({
          method: "put",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac"),
          data: {
            handlingMethod: 4,
            operator: operator,
            password: vm.$config.base.password,
            id: vm.info.id,
            responsibleParty: 5
          }
        }).then(function (res) {
          vm.tt.clear();

          if (res.code != 1) {
            _toast.default.fail({
              message: res.message,
              forbidClick: true,
              overlay: true,
              duration: 2000
            });

            return;
          }

          _toast.default.success({
            message: '已进行报废处理',
            forbidClick: true,
            overlay: true,
            duration: 2000
          });

          setTimeout(function () {
            vm.$router.replace({
              path: "/factory/quality/qualityList"
            });
          }, 2000);
        }).catch(function (err) {
          console.log(err);
          vm.tt.clear();
        });
      },
      // 查看返厂记录
      onclickShowRecord: function onclickShowRecord() {
        var vm = this;
        vm.isShowRecord = !vm.isShowRecord;
      },
      // 恢复质检档位
      onclickDefaultLevel: function onclickDefaultLevel() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;
        vm.$http({
          type: "POST",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "tlv2/defaultLevel"),
          data: {
            password: vm.$config.base.password,
            operator: vm.$localStorage.getItem(USER_NAME),
            uniqueId: vm.$route.params.id
          },
          except: true
        }).then(function (res) {
          if (res.code == 1) {
            _toast.default.success({
              message: '恢复成功',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });

            setTimeout(function () {
              vm.loadDetail();
            }, 2000);
          } else {
            _toast.default.fail({
              message: res.message,
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }
        });
      }
    }
  };
  _exports.default = _default;
});
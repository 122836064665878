var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "client-list-page" },
    _vm._l(_vm.clientList, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "list-item",
          on: {
            click: function($event) {
              return _vm.onclickClientDetail(index)
            }
          }
        },
        [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "image" }, [
              _c("img", { attrs: { src: item.imgUrl } })
            ]),
            _c("div", { staticClass: "info" }, [
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
              _c("div", { staticClass: "text" }, [
                _c("div", [
                  _vm._v(_vm._s(item.provinceName) + _vm._s(item.cityName))
                ]),
                _c("div", { staticClass: "line-icon" }),
                _c("div", [_vm._v(_vm._s(item.fishName))])
              ])
            ])
          ]),
          _c("div", { staticClass: "right" }, [
            _c(
              "div",
              { class: ["state", item.riskLevel !== 1 ? "yellow" : ""] },
              [
                item.riskLevel === 1
                  ? _c("span", [_vm._v("低风险")])
                  : _vm._e(),
                item.riskLevel === 2
                  ? _c("span", [_vm._v("中风险")])
                  : _vm._e(),
                item.riskLevel === 3 ? _c("span", [_vm._v("高风险")]) : _vm._e()
              ]
            ),
            _c("div", { staticClass: "arrow" })
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-factory-quality-history" },
    [
      !_vm.isRuiy
        ? _c("div", { staticClass: "device-info" }, [
            _vm.info.dType == 3
              ? _c("img", {
                  attrs: { src: _vm.ossHost + "img/ry_icon_v2.png", alt: "" }
                })
              : _vm._e(),
            _vm.info.dType == 2
              ? _c("img", {
                  staticClass: "device-icon",
                  attrs: { src: _vm.ossHost + "img/icon-tl-box.jpg", alt: "" }
                })
              : _vm._e(),
            _vm.info.dType == 6
              ? _c("img", {
                  staticClass: "device-icon",
                  attrs: { src: _vm.ossHostPond + "img/icp.jpeg", alt: "" }
                })
              : _vm._e(),
            _vm.info.deviceType == 12
              ? _c("img", {
                  staticClass: "device-icon",
                  attrs: {
                    src: _vm.ossHostPond + "img/icon_water_box.png",
                    alt: ""
                  }
                })
              : _vm._e(),
            _c("span", [
              _vm._v(_vm._s(_vm._f("filterOrderStatus")(_vm.info.status)))
            ]),
            _c("div", { staticClass: "device-num" }, [
              _vm._v(_vm._s(_vm.info.uniqueId) + " "),
              _vm.deviceType && _vm.info.dType == 3
                ? _c("span", [_vm._v("(" + _vm._s(_vm.deviceType) + ")")])
                : _vm._e()
            ]),
            _vm.deviceType && _vm.info.dType != 3
              ? _c("div", { staticClass: "devive-name" }, [
                  _vm._v(_vm._s(_vm.deviceType))
                ])
              : _vm._e(),
            _c("div", { staticClass: "device-batch" }, [
              _vm._v("批次号：" + _vm._s(_vm.info.productionBatch || "-"))
            ])
          ])
        : _c("div", { staticClass: "device-info device-kzq" }, [
            _c("span", [
              _vm._v(_vm._s(_vm._f("filterOrderStatus")(_vm.info.status)))
            ]),
            _c("div", { staticClass: "device-num" }, [
              _vm._v("设备编号：" + _vm._s(_vm.info.uniqueId) + " "),
              _vm.deviceType
                ? _c("span", [_vm._v("(" + _vm._s(_vm.deviceType) + ")")])
                : _vm._e()
            ]),
            _vm.info.mainboardId
              ? _c("div", { staticClass: "device-batch" }, [
                  _vm._v(
                    "主板编号：" + _vm._s(_vm.info.mainboardId || "-") + " "
                  ),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.updateBarCode(1)
                        }
                      }
                    },
                    [_vm._v("修改")]
                  )
                ])
              : _c("div", { staticClass: "device-batch" }, [
                  _vm._v("主板编号： "),
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.updateBarCode(0)
                        }
                      }
                    },
                    [_c("i", { staticClass: "el-icon-link" }), _vm._v("关联")]
                  )
                ])
          ]),
      _vm.info.dType == 2
        ? _c("div", { staticClass: "step-list tlj-tip" }, [
            _c("div", { staticClass: "tip-title" }, [
              _vm._v("震动档位: "),
              _vm.info.ntlDeviceConfigView.speedLevel == 1
                ? _c("span", [_vm._v("低")])
                : _vm._e(),
              _vm.info.ntlDeviceConfigView.speedLevel == 2
                ? _c("span", [_vm._v("中")])
                : _vm._e(),
              _vm.info.ntlDeviceConfigView.speedLevel == 3
                ? _c("span", [_vm._v("高")])
                : _vm._e(),
              _vm.info.ntlDeviceConfigView.speedLevel == 100
                ? _c("span", [
                    _vm._v(
                      "自定义(" +
                        _vm._s(_vm.info.ntlDeviceConfigView.speedLevelSDMin) +
                        "-" +
                        _vm._s(_vm.info.ntlDeviceConfigView.speedLevelSDMax) +
                        ")"
                    )
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass: "tip-btn",
                on: { click: _vm.onclickDefaultLevel }
              },
              [_vm._v("恢复出厂设置")]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "step-list" },
        [
          _c("div", { staticClass: "head" }, [
            _c("div", { staticClass: "head-title" }, [_vm._v("返厂记录")]),
            _c(
              "div",
              { staticClass: "head-btn", on: { click: _vm.onclickShowRecord } },
              [_vm._v("更多")]
            )
          ]),
          _c(
            "Steps",
            { attrs: { direction: "vertical" } },
            [
              _vm.info.logs
                ? _c("Step", [
                    _c("div", [_vm._v(_vm._s(_vm.info.logs[0].content))]),
                    _c("div", { staticClass: "step-time" }, [
                      _vm._v(_vm._s(_vm._f("getYMD")(_vm.info.logs[0].time)))
                    ]),
                    _vm.info.logs[0].type == 1 || _vm.info.logs[0].type == 2
                      ? _c("div", { staticClass: "edit-order-info" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.viewOrderInfo(
                                    0,
                                    _vm.info.logs[0].type
                                  )
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          ),
                          _vm.info.modifyTimes && _vm.info.logs[0].type == 1
                            ? _c(
                                "span",
                                {
                                  staticClass: "edit-order-btn",
                                  on: { click: _vm.editOrder }
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "step-list" }, [
        _c("div", { staticClass: "head" }, [_vm._v("退换信息")]),
        _c("div", { staticClass: "return-body" }, [
          _vm.info.firstBidnAt
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [
                  _vm._v("首次绑定日期")
                ]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm._f("getYMD")(_vm.info.firstBidnAt)))
                ])
              ])
            : _vm._e(),
          _vm.isRuiy
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("使用年限")]),
                _c(
                  "div",
                  {
                    class: {
                      "item-text": true,
                      "err-tag": _vm.info.useMonth >= 24
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(parseInt(_vm.info.useMonth / 12)) +
                        "年" +
                        _vm._s(_vm.info.useMonth % 12) +
                        "月"
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm.info.ryFactoryType > 0 || _vm.info.dType == 3
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("生产工厂")]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.info.ryFactoryType == 1 ? "乐行" : "旺博"))
                ])
              ])
            : _vm._e(),
          _vm.info.dType == 6 || _vm.info.deviceType == 12
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("生产工厂")]),
                _c("div", { staticClass: "item-text" }, [_vm._v("豪派")])
              ])
            : _vm._e(),
          _vm.info.createParty == 5
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("退换原因")]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.info.tag || "暂无"))
                ])
              ])
            : _vm._e(),
          _vm.info.createParty == 3
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("故障类型")]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.info.factoryTag || "暂无"))
                ])
              ])
            : _vm._e(),
          _vm.info.createParty == 3
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("故障描述")]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.info.factoryReason || "暂无"))
                ])
              ])
            : _vm._e(),
          _vm.info.createParty == 5
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label" }, [_vm._v("退换时间")]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm._f("getYMD")(_vm.info.createAt)))
                ])
              ])
            : _vm._e(),
          _vm.info.createParty == 5
            ? _c("div", { staticClass: "return-item item-mask" }, [
                _c("div", { staticClass: "item-label" }, [
                  _vm._v("经销商备注")
                ]),
                _c("div", { staticClass: "item-text" }, [
                  _vm._v(_vm._s(_vm.info.reason || "暂无"))
                ])
              ])
            : _vm._e(),
          _vm.info.dType != 6 && _vm.info.deviceType != 12
            ? _c("div", { staticClass: "return-item" }, [
                _c("div", { staticClass: "item-label chart-label" }, [
                  _vm._v("离线前一个月告警")
                ]),
                _c("div", { staticClass: "item-text" })
              ])
            : _vm._e(),
          _c("div", {
            style: { width: _vm.canvasWith + "px" },
            attrs: { id: "warning-chart" }
          })
        ])
      ]),
      _vm.info.status == 1 && !(!_vm.info.isInFactoryQcAt && _vm.isRuiy)
        ? _c(
            "div",
            {
              staticClass: "btn",
              on: {
                click: function($event) {
                  return _vm.viewOrderInfo(1)
                }
              }
            },
            [_vm._v("填写质检记录")]
          )
        : _vm._e(),
      _vm.info.status == 1 && !_vm.info.isInFactoryQcAt && _vm.isRuiy
        ? _c(
            "div",
            { staticClass: "btn", on: { click: _vm.onclickSubmitBtn } },
            [_vm._v("报废处理")]
          )
        : _vm._e(),
      _c(
        "Popup",
        {
          staticClass: "record-popup",
          attrs: {
            round: true,
            position: "center",
            "close-on-click-overlay": false
          },
          model: {
            value: _vm.isShowRecord,
            callback: function($$v) {
              _vm.isShowRecord = $$v
            },
            expression: "isShowRecord"
          }
        },
        [
          _c(
            "div",
            { staticClass: "step-list" },
            [
              _c(
                "Steps",
                { attrs: { direction: "vertical" } },
                _vm._l(_vm.info.logs, function(item) {
                  return _c("Step", { key: item.time }, [
                    _c("div", [_vm._v(_vm._s(item.content))]),
                    _c("div", { staticClass: "step-time" }, [
                      _vm._v(_vm._s(_vm._f("getYMD")(item.time)))
                    ]),
                    item.type == 1 || item.type == 2
                      ? _c("div", { staticClass: "edit-order-info" }, [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.viewOrderInfo(0, item.type)
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          ),
                          _vm.info.modifyTimes && item.type == 1
                            ? _c(
                                "span",
                                {
                                  staticClass: "edit-order-btn",
                                  on: { click: _vm.editOrder }
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c("img", {
            staticClass: "record-popup-close-btn",
            attrs: { src: _vm.ossHostPond + "img/icon_close.png" },
            on: { click: _vm.onclickShowRecord }
          })
        ]
      ),
      _c(
        "Popup",
        {
          staticStyle: { "min-height": "20%" },
          attrs: {
            round: true,
            position: "center",
            "close-on-click-overlay": false
          },
          model: {
            value: _vm.isShowPopup,
            callback: function($$v) {
              _vm.isShowPopup = $$v
            },
            expression: "isShowPopup"
          }
        },
        [
          _c("div", { staticClass: "pop-box" }, [
            _c("div", { staticClass: "pop-header" }, [_vm._v("关联主板编码")]),
            _c(
              "div",
              { staticClass: "pop-body" },
              [
                _c("Field", {
                  attrs: {
                    label: "",
                    placeholder: "请输入主板编号",
                    "input-align": "left",
                    "right-icon": "scan",
                    maxlength: "13",
                    type: "string"
                  },
                  on: { "click-right-icon": _vm.onClickScanIcon },
                  model: {
                    value: _vm.barCode,
                    callback: function($$v) {
                      _vm.barCode = $$v
                    },
                    expression: "barCode"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "pop-footer" }, [
              _c(
                "div",
                {
                  staticClass: "footer-btn",
                  on: {
                    click: function($event) {
                      return _vm.onclickClosePop(0)
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm.barCode && _vm.barCode.length >= 10
                ? _c(
                    "div",
                    {
                      staticClass: "footer-btn sure-btn",
                      on: {
                        click: function($event) {
                          return _vm.onclickClosePop(1)
                        }
                      }
                    },
                    [_vm._v("确 定 ")]
                  )
                : _c("div", { staticClass: "footer-btn" }, [_vm._v("确 定")])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/es.string.trim");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/dialog/style", "vant/es/dialog", "vant/es/icon/style", "vant/es/icon", "vant/es/popup/style", "vant/es/popup", "vant/es/button/style", "vant/es/button", "vant/es/tab/style", "vant/es/tab", "vant/es/tabs/style", "vant/es/tabs", "vant/es/search/style", "vant/es/search", "@/components/factory/qualityItem"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/icon/style"), require("vant/es/icon"), require("vant/es/popup/style"), require("vant/es/popup"), require("vant/es/button/style"), require("vant/es/button"), require("vant/es/tab/style"), require("vant/es/tab"), require("vant/es/tabs/style"), require("vant/es/tabs"), require("vant/es/search/style"), require("vant/es/search"), require("@/components/factory/qualityItem"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.dialog, global.style, global.icon, global.style, global.popup, global.style, global.button, global.style, global.tab, global.style, global.tabs, global.style, global.search, global.qualityItem);
    global.qualityList = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _dialog, _style3, _icon, _style4, _popup, _style5, _button, _style6, _tab, _style7, _tabs, _style8, _search, _qualityItem) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _dialog = _interopRequireDefault(_dialog);
  _icon = _interopRequireDefault(_icon);
  _popup = _interopRequireDefault(_popup);
  _button = _interopRequireDefault(_button);
  _tab = _interopRequireDefault(_tab);
  _tabs = _interopRequireDefault(_tabs);
  _search = _interopRequireDefault(_search);
  _qualityItem = _interopRequireDefault(_qualityItem);
  var _default = {
    name: "RyQualityList",
    components: {
      Search: _search.default,
      Tabs: _tabs.default,
      Tab: _tab.default,
      QualityItem: _qualityItem.default,
      Button: _button.default,
      Popup: _popup.default,
      Icon: _icon.default
    },
    data: function data() {
      var vm = this;
      var ossHost = this.$config.base.ossHost;
      var activeIndex = 0;
      var index = vm.$localStorage.getItem('checkMenuItemIndex');
      activeIndex = index > -1 ? index : 0;
      return {
        ossHost: ossHost,
        name: "",
        value: "",
        activeIndex: activeIndex,
        list: [],
        isShowAll: false,
        loading: false,
        qualityLoading: false,
        type: 0,
        waitQcCountText: '待质检',
        isKzqSearchRes: false,
        fType: window.fType,
        isShowCreateQuality: false,
        inputCode: '',
        isShowMenuOption: false,
        menuOption: [{
          text: '全部',
          value: 0
        }, {
          text: '测水盒',
          value: 1
        }, {
          text: '摄像头',
          value: 2
        }],
        menuOptionValue: 0
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (vm.$config.base.fList.indexOf(fType) > -1) {
        vm.name = name;

        if (!name) {
          vm.$router.replace({
            path: "/factory/login"
          });
          return;
        }

        fType == 'yingwo' && vm.$router.replace({
          path: "/factory/login"
        });
        vm.type = fType == 'ruiyin' ? 1 : 3; // 摄像头/测水盒

        fType == 'haopai' && (vm.type = 6); // 投料机控制盒质检记录

        if (window.fType == 5) {
          vm.isLoss = window.isLoss;
          vm.fType = 5;
          vm.type = 2; // 投料机控制盒返厂定损

          if (vm.isLoss) {
            document.title = '返厂定损';
          } else {
            document.title = '质检记录';
          }
        }
      } else {
        vm.$router.replace({
          path: "404"
        });
      }

      vm.pageNo = 1;
      vm.pageSize = 10;
      vm.weixinInit();
      vm.loadCheckList();
    },
    methods: {
      switchAccount: function switchAccount() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认退出当前账号？",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          vm.$localStorage.removeItem(USER_NAME);
          vm.$router.replace({
            path: "/factory/login?type=1"
          });
        }).catch(function () {// on cancel
        });
      },
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
      },
      // 切换菜单
      changeMenuValue: function changeMenuValue(item) {
        var vm = this;
        vm.menuOptionValue = item.value;
        vm.isShowMenuOption = false;
        vm.pageNo = 1;
        vm.pageSize = 10;
        vm.loadCheckList();
      },
      scan: function scan() {
        var vm = this;
        window.wx.scanQRCode({
          needResult: 1,
          scanType: ["barCode", "qrCode"],
          success: function success(res) {
            var result = res.resultStr && res.resultStr.split('deviceId=')[1];

            if (!result && vm.type != 1) {
              _dialog.default.confirm({
                title: "提示",
                message: "非设备编号~",
                confirmButtonColor: "#32BE32",
                showCancelButton: false
              });

              return;
            }

            result ? vm.$http({
              type: "get",
              url: "".concat(vm.$config.base.BASE_URL, "admin/iotTool/product/device/").concat(result, "/isNone"),
              except: true
            }).then(function (res) {
              var code = res.code,
                  data = res.data,
                  message = res.message;

              if (code != 1 || !data.length) {
                _toast.default.fail({
                  message: message || data.length ? '服务器繁忙~' : '设备不存在',
                  forbidClick: false,
                  overlay: true,
                  duration: 2000
                });

                return;
              }

              vm.onSearch(data[0].unique_id);
            }).catch(function (err) {
              console.log(err);
            }) : vm.onSearch(res.resultStr.split(',').length > 1 ? res.resultStr.split(',')[1] : res.resultStr);
          }
        });
      },
      // searchType, 1主板码为11位
      // 正常主板码为12位数，因为开始录入限制了11位，如果12位查不到就用11位再查一次
      onSearch: function onSearch(code) {
        var vm = this;
        var loadUrl = "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/detail");
        var params = {
          uniqueId: code || vm.value.trim()
        };
        vm.inputCode = params.uniqueId;

        if (vm.value.trim().length != 8 && code.length != 8 && vm.type != 1) {
          _toast.default.fail({
            message: '请输入8位编码查询',
            forbidClick: false,
            overlay: true,
            duration: 2000
          });

          return;
        }

        vm.tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });

        if (vm.type == 1) {
          loadUrl = "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/search");
          params = {
            uniqueOrBroadId: code || vm.value.trim()
          }; // searchType == 1 && (params.uniqueOrBroadId = params.uniqueOrBroadId.substr(0, 11))
        }

        if (window.fType == 5 && !vm.isLoss) {
          document.title = '质检记录';
          loadUrl = "".concat(vm.$config.base.PROBE_BASE_URL, "tl/qcList");
          params.type = 2;
        }

        vm.$http({
          type: "get",
          url: loadUrl,
          params: params,
          except: true
        }).then(function (res) {
          vm.tt.clear();

          var _ref = res.data || {},
              data = _ref.data;

          if (window.fType == 5 && !vm.isLoss && !data.length) {
            _toast.default.fail({
              message: "\u8BE5\u8BBE\u5907\u6682\u65E0\u8D28\u68C0\u8BB0\u5F55",
              forbidClick: false,
              overlay: true,
              duration: 2000
            });

            return;
          }

          if (res && res.data) {
            if (vm.type == 1) {
              // if (!res.data.length && searchType != 1) {
              //     vm.onSearch(code, 1);
              // }
              vm.isKzqSearchRes = true;
              vm.list = res.data;
              vm.isShowAll = true;
              return;
            } // 不是同种类型不可查找


            var dType = vm.$strTool.resetDeviceType(res.data.deviceType); // 豪派有两个设备

            if (vm.type == 6) {
              if (dType != 6 && res.data.deviceType != 12) {
                _toast.default.fail({
                  message: '设备不存在，请重新确认',
                  forbidClick: true,
                  overlay: true,
                  duration: 2000
                });

                return;
              }
            } else {
              if (dType != vm.type) {
                _toast.default.fail({
                  message: '设备不存在，请重新确认',
                  forbidClick: true,
                  overlay: true,
                  duration: 2000
                });

                return;
              }
            }

            var path = "/factory/quality/history/".concat(code || vm.value.trim());

            if (window.fType == 5 && !vm.isLoss) {
              path = "/factory/quality/tlQaInfo/".concat(data[0].id, "?uniqueId=").concat(data[0].uniqueId, "&time=").concat(data[0].submitAt, "&name=").concat(data[0].operatorName);
            }

            vm.$router.push({
              path: path
            });
          } else {
            if (res.code == 3) {
              vm.isShowCreateQuality = true;
              return;
            }

            _toast.default.fail({
              message: '设备不存在，请重新确认',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }
        }).catch(function (err) {
          vm.tt.clear();
          console.log(err);
        });
      },
      // 创建返厂定损记录
      onclickCreateQualityBtn: function onclickCreateQualityBtn() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;
        var name = vm.$localStorage.getItem(USER_NAME);
        var url = "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/").concat(vm.type == 1 ? 'ruiy' : 'inc');

        if (window.fType == 5) {
          url = "".concat(vm.$config.base.PROBE_BASE_URL, "tl/returnFac");
        } // 豪派创建摄像头/测水盒


        if (vm.type == 6) {
          url = "".concat(vm.$config.base.PROBE_BASE_URL, "/haoP/returnFac");
        }

        vm.qualityLoading = true;
        vm.$http({
          type: "post",
          url: url,
          data: {
            password: vm.$config.base.password,
            operator: name,
            uniqueId: vm.inputCode || vm.value.trim()
          }
        }).then(function (res) {
          if (res.code == 1) {
            vm.$router.push({
              path: "/factory/quality/history/".concat(vm.inputCode || vm.value.trim())
            });
            vm.qualityLoading = false;

            _toast.default.success({
              message: '创建成功',
              forbidClick: false,
              overlay: true,
              duration: 2000
            });
          }
        });
      },
      onCancel: function onCancel() {},
      changeTab: function changeTab() {
        var vm = this;

        if (vm.activeIndex > -1) {
          vm.list = [];
          vm.pageNo = 1;
          vm.isShowAll = false;
          vm.loading = false;
          vm.loadMore();
        }

        vm.$localStorage.setItem('checkMenuItemIndex', vm.activeIndex);
      },
      loadMore: function loadMore() {
        var vm = this;

        if (vm.loading) {
          return;
        }

        vm.loadCheckList();
      },
      loadCheckList: function loadCheckList() {
        var vm = this;
        var status = vm.activeIndex ? 3 : 1;
        var url = 'returnFac/List';
        var params = {
          pageNo: vm.pageNo,
          pageSize: vm.pageSize,
          status: vm.activeIndex ? 3 : 1,
          deviceType: vm.type
        };
        vm.isKzqSearchRes = false;
        vm.loading = true;
        vm.isShowAll = false;

        if (window.fType == 5) {
          if (vm.isLoss) {
            params.deviceType = 2;
          } else {
            url = 'tl/qcList';
            params.type = 2;
          }
        } // 豪派区分 摄像头/测水盒


        if (vm.type == 6) {
          // 全部
          vm.menuOptionValue == 0 && (params.deviceType = 56); // 测水盒

          vm.menuOptionValue == 1 && (params.deviceType = 5); // 摄像头

          vm.menuOptionValue == 2 && (params.deviceType = 6);
        }

        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL).concat(url),
          params: params,
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          } // 投料机质检列表回调处理


          if (window.fType == 5 && !vm.isLoss) {
            vm.tlQaListCallback(res);
            return;
          }

          var _ref2 = res.data || {},
              returnDeviceViews = _ref2.returnDeviceViews,
              waitQcCount = _ref2.waitQcCount;

          if (returnDeviceViews.length && returnDeviceViews[0].status !== status) {
            return;
          }

          vm.list = vm.pageNo == 1 ? returnDeviceViews : vm.list.concat(returnDeviceViews || []);
          vm.waitQcCountText = "\u5F85\u8D28\u68C0(".concat(waitQcCount, ")");
          vm.loading = false;

          if (!returnDeviceViews || returnDeviceViews.length < vm.pageSize) {
            vm.isShowAll = true;
          } else {
            vm.pageNo++;
          }
        });
      },
      tlQaListCallback: function tlQaListCallback(res) {
        var vm = this;
        var data = res.data.data;
        vm.list = vm.pageNo == 1 ? data : vm.list.concat(data || []);
        vm.loading = false;

        if (!data || data.length < vm.pageSize) {
          vm.isShowAll = true;
        } else {
          vm.pageNo++;
        }
      },
      viewDescription: function viewDescription() {
        this.$router.push({
          path: "/factory/description?type=".concat(this.type)
        });
      },
      jumpCreateOrderPage: function jumpCreateOrderPage() {
        this.$router.push({
          path: "/factory/quality/createOrder?type=".concat(this.type)
        });
      }
    }
  };
  _exports.default = _default;
});